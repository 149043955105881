import { Injectable } from '@angular/core';
import { DataConfigService } from "../../modulo-comunes/servicios/data-config-servicios";


@Injectable({
  providedIn: 'root'
})
export class LogService {
  constructor(
    private dataConfigService: DataConfigService
  ) { }

  //En firebase guardo en la ruta de config el nivel desde el cual se mostrará el log
  //Cualquier mensaje que venga con logLevelThis >= a ese nivel se mostrará.
  //Cuando inicia, el nivel viene de config-data.ts (actualmente es 3)
  //Niveles:
  //1 y 2: debug, importantes solo durante el desarrollo. 
  //3: debug de interés actual 
  //4 y 5: info, para reportar información interesante.
  //6 y 7: warning, una situación que pueda poner en peligro el sistema.
  //8 y 9: error.
  //Con este método se pierden el módulo y la línea donde se produjo el error, por eso en todo mensaje debe haber una
  //indicación del módulo, archivo y un mensaje claro y si fuera posible único, para que sea fácil ubicar el sitio del mensaje.
  //Cáda módulo tiene una letra: _app, Básico, Común, Público, Usuario y Admin.
  //Cada tipo de archivo tiene una letra: Componente, Elemento, Servicio, Directiva.
  //Cada archivo tiene dos letras: CambiopassWord, FooTer, INgreso, InIcio, NuevoUsr, TopBar, 
  //RUteo, MOdule, COmponent, ConsultaUsr, USuario, ComboBox, MsgBox, DropDown, EntPres, ConsultaEntPres

  logLevel: string; //this.dataConfigService.cfg['log01'].valor;

  consoleLog(logLevelThis: string, mensajeLog: string, objLinea?) {
    this.logLevel = this.dataConfigService.cfgVarias.logNivel;
    let linea: string = '';
    if (objLinea != undefined) {
      linea = objLinea.file + ' (línea ' + objLinea.line + ')/r/n';
    }
    if (parseInt(logLevelThis) >= parseInt(this.logLevel)) {
      console.log('[' + logLevelThis + '/' + this.logLevel + '] ' + linea + mensajeLog);
    }
  }


}
