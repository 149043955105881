<div class="section-texto wf-section">
    <h2 class="headinginvisible">.</h2>
</div>
<div data-w-id="fa9537cb-b68c-698a-bdc6-638c33a059db" class="section-foto-1 wf-section">
    <div class="columns1 w-row">
        <div class="w-col w-col-6">
            <h1 class="heading"><br>Página no encontrada!<br>‍</h1>
            <p class="paragraph">Si es posible, indíquenos qué ocurrió para llegar a este mensaje.<br></p>
            <p><br><br><br><br><br><br><br></p>
        </div>
        <div class="w-col w-col-6"></div>
    </div>
</div>
