export class EmpIdPref {
    eid: string;
    prefijoBase: string;
}

export class EmpAut {
    autorizado: string;
    //base:string;
    nombre: string;
    pagosOk: string;
    prefijoBase: string;
    producto: string;
    ruc: string;
    fechaHora: string;
    eid: string;
    subproducto: string;
    userAutSolicNombre: string;
    userAutSolicMail: string;
}

export class PedirAccesoEmp {
    autorizado: string;
    nombre: string;         //nombre de la empresa digitado por el usuario
    nombreReal: string;     //nombre real de la empresa
    ruc: string;
    fechaHora: string;
    paeid: string;
    tipoUsuarioSolicitado: string;
    uid: string;
    userAutSolicNombre: string;
    userAutSolicMail: string;
}

export class InfoConsumo {
    pacientes: number;
    base: number;
    storage: number;
}