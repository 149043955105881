export class CfgVarias {
  logNivel: string;
  logEstados: string;
  logo: string;
  logoUrl: string;
  nombreCorto: string;
  nombreAmigable: string;
  sistema: string;
}

/**
 * TextosPorProducto: nombres a usar según producto y subproducto
 */
export class TextosPorProducto {
  denomPacLow: string;
  denomPacFirstUp: string;
  denomProdLow: string;
  denomProdFirstUp: string;
  denomPropietarioLow: string;
  denomPropietarioFirstUp: string;
  denomCodigoPacLow: string;
  denomCodigoPacFirstUp: string;
  denomOficinaLow: string;
  denomOficinaFirstUp: string;
  denomAtiendeLow: string;
  denomAtiendeFirstUp: string;
  // Estos datos parecen ser comunes, pero en otras empresas como Dior estos datos pueden cambiar
  denomDiagLow: string;
  denomDiagFirstUp: string;
  denomPresLow: string;
  denomPresLowPlural: string;
  denomPresFirstUp: string;
  denomProcLow: string;
  denomProcFirstUp: string;
}

export class CfgSecure {
  archfirma: string;
  pwdfirma: string;
  fechaexpirafirma: string;
  rutafirma: string;

  mail: string;
  mailfrom: string;
  mailpwd: string;
  mailhost: string;
  mailport: number;
  mailsecure: boolean;

  keeptxtpdf: string;

  rutagen: string;
  rutaaut: string;
  rutatxtpdf: string;

  rutafir: string;
  rutaenv: string;
  rutaanul: string;
}

export class CoordCampo {
  left: string;
  top: string;
  width: string;
  height: string;
}

export class CoordLeftTop {
  left: string;
  top: string;
}

//cada opción de un dropdown, es el menor elemento del menú
export class Opcion {
  opcion: string;
  ruta: string;
  param?: string;
}

//título y todas las opciones de un dropdown 
export class Drop {
  titulo: string;
  opciones: Opcion[];
}

//pantalla y todos los drops de esa pantalla
//por ahora no estoy usando ni Menu ni Menues, solo los Drop
export class Menu {
  pantalla: string;
  drops: Drop[];
}

export class Menues {
  tipousr: string;
  menues: Menu[];
}

export class CfgOpcionCombo {
  abrev: string;
  opc: string;
}

export class CfgTemaOpciones {
  tema: string;
  opciones: CfgOpcionCombo[];
}

export class Estilo {
  atributo: string;
  valor: string;
}
