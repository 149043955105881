/* eslint-disable @typescript-eslint/no-empty-function */
import { Injectable } from '@angular/core';
import { LogService } from '../servicios/log-servicios'

import { EmpAut } from "../interfaces/empresa-interfaces";
import { TextosPorProducto } from '../interfaces/varias-interfaces';

@Injectable({
  providedIn: 'root'
})
export class DataEmpresaService {
  constructor(
    private logService: LogService
  ) { }

  //sitioElegido no tiene un subject ni un observable porque su grabación es instantánea
  sitioElegido: string = '...';   //Puede ser EasyMed o Efisys
  sitioElegidoUpdate(url: string): void {
    if (url.toLowerCase().indexOf('easymed.site') != -1 || url.indexOf('4200') != -1) {
      this.sitioElegido = 'EasyMed';
    } else if (url.toLowerCase().indexOf('efisys.site') != -1 || url.indexOf('4300') != -1) {
      this.sitioElegido = 'Efisys';
    } else {
      this.sitioElegido = '...';
    }
    this.logService.consoleLog('2', 'CS DES sitioElegido: ' + this.sitioElegido)
  }

  empAut: EmpAut = new EmpAut;
  empAutElegida: string = '';   //Nombre de la empresa elegida por el usr en el SelectBox
  empAutElegidaPrefijo: string = '';   //prefijoBase de la empresa elegida por el usr en el SelectBox
  //empresa_ = new ReplaySubject<EmpAut>(1);
  //empresa$ = this.empresa_.asObservable();
  empAutUpdate(emp: EmpAut): void {
    this.empAut = emp;
    //this.empAut.next(emp);
    this.empAutElegida = this.empAut.nombre;
    this.empAutElegidaPrefijo = this.empAut.prefijoBase;
    this.logService.consoleLog('2', 'CS DES empAutElegida: ' + this.empAutElegida)
  }

  //a veces va directo a this.dataPacienteService.paramPidElegido$.pipe(take(1)).subscribe(paramPid => {
  //sin pasar por las partes anteriores del ngOnInit. Uso esta var para ver si pasó por el inicio del ngOnInit
  inicioNgOnInit: boolean = false;
  inicioNgOnInitUpdate(b: boolean): void {
    this.inicioNgOnInit = b;
  }

  //se usa para denominar textos en pantallas como paciente o producto  (EasyMed) vs cliente o artículo (Efisys)
  //los que dicen "low" tienen todas las letras en minúsculas, "firstup" tienen la primera letra en mayúscula.
  denomPacLow: string = '';
  denomPacFirstUp: string = '';
  denomProdLow: string = '';
  denomProdFirstUp: string = '';
  denomPropietarioLow: string = '';
  denomPropietarioFirstUp: string = '';
  denomCodigoPacLow: string = '';
  denomCodigoPacFirstUp: string = '';
  denomOficinaLow: string = '';
  denomOficinaFirstUp: string = '';
  denomAtiendeLow: string = '';
  denomAtiendeFirstUp: string = '';
  denomDiagLow: string = '';
  denomDiagFirstUp: string = '';
  denomPresLow: string = '';
  denomPresLowPlural: string = '';
  denomPresFirstUp: string = '';
  denomProcLow: string = '';
  denomProcFirstUp: string = '';

  denomUpdate(tpp: TextosPorProducto): void {
    this.denomPacLow = tpp.denomPacLow;
    this.denomPacFirstUp = tpp.denomPacFirstUp;
    this.denomProdLow = tpp.denomProdLow;
    this.denomProdFirstUp = tpp.denomProdFirstUp;
    this.denomPropietarioLow = tpp.denomPropietarioLow;
    this.denomPropietarioFirstUp = tpp.denomPropietarioFirstUp;
    this.denomCodigoPacLow = tpp.denomCodigoPacLow;
    this.denomCodigoPacFirstUp = tpp.denomCodigoPacFirstUp;
    this.denomOficinaLow = tpp.denomOficinaLow;
    this.denomOficinaFirstUp = tpp.denomOficinaFirstUp;
    this.denomAtiendeLow = tpp.denomAtiendeLow;
    this.denomAtiendeFirstUp = tpp.denomAtiendeFirstUp;
    //En otras versiones de EasyMed como Dior estos datos pueden cambiar
    this.denomDiagLow = tpp.denomDiagLow;
    this.denomDiagFirstUp = tpp.denomDiagFirstUp;
    this.denomPresLow = tpp.denomPresLow;
    this.denomPresLowPlural = tpp.denomPresLowPlural;
    this.denomPresFirstUp = tpp.denomPresFirstUp;
    this.denomProcLow = tpp.denomProcLow;
    this.denomProcFirstUp = tpp.denomProcFirstUp;
  }

}
