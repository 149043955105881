/* eslint-disable @typescript-eslint/no-empty-function */
import { Injectable } from '@angular/core';

import { CfgVarias, CfgSecure, CfgOpcionCombo } from "../interfaces/varias-interfaces";
import { ReplaySubject } from 'rxjs';
import { cfgInicial } from '../datos/config-data';
import { DatosSRI } from '../interfaces/factura-interfaces';

@Injectable({
  providedIn: 'root'
})
export class DataConfigService {
  constructor(
  ) { }


  cfgVarias: CfgVarias = cfgInicial;
  cfgVarias_ = new ReplaySubject<CfgVarias>(1);
  cfgVarias$ = this.cfgVarias_.asObservable();
  cfgVariasUpdate(conf: CfgVarias) {
    console.log('[9/_] cfgVarias log level: ' + conf.logNivel)
    this.cfgVarias = conf;
    this.cfgVarias_.next(conf);
  }

  datosSRI: DatosSRI = new DatosSRI;
  datosSRI_ = new ReplaySubject<DatosSRI>(1);
  datosSRI$ = this.datosSRI_.asObservable();
  rutaFac: string = '';
  datosSRIUpdate(dsri: DatosSRI) {
    console.log('[9/_] datosSRI ruc: ' + dsri.ruc)
    this.datosSRI = dsri;
    this.datosSRI_.next(dsri);
    if (this.datosSRI.ambienteactual == 'Producción') {
      this.rutaFac = '/facturas/produccion';
    } else {
      this.rutaFac = '/facturas/pruebas';
    }
  }

  cfgSecure: CfgSecure = new CfgSecure;
  cfgSecure_ = new ReplaySubject<CfgSecure>(1);
  cfgSecure$ = this.cfgSecure_.asObservable();
  cfgSecureUpdate(conf: CfgSecure) {
    console.log('[9/_] cfgSecure mail: ' + conf.mail)
    this.cfgSecure = conf;
    this.cfgSecure_.next(conf);
  }

  activos: CfgOpcionCombo[] = [];
  activosUpdate(estArr: CfgOpcionCombo[]) {
    this.activos = estArr;
  }

  estCiviles: CfgOpcionCombo[] = [];
  estCivilesUpdate(estArr: CfgOpcionCombo[]) {
    this.estCiviles = estArr;
  }

  razas: CfgOpcionCombo[] = [];
  razasUpdate(estArr: CfgOpcionCombo[]) {
    this.razas = estArr;
  }

  tipoIds: CfgOpcionCombo[] = [];
  tipoIdsUpdate(estArr: CfgOpcionCombo[]) {
    this.tipoIds = estArr;
  }

  sexos: CfgOpcionCombo[] = [];
  sexosUpdate(estArr: CfgOpcionCombo[]) {
    this.sexos = estArr;
  }

  tipoPieles: CfgOpcionCombo[] = [];
  tipoPielesUpdate(estArr: CfgOpcionCombo[]) {
    this.tipoPieles = estArr;
  }

  tipoSangres: CfgOpcionCombo[] = [];
  tipoSangresUpdate(estArr: CfgOpcionCombo[]) {
    this.tipoSangres = estArr;
  }

  docelecAmbientes: CfgOpcionCombo[] = [];
  docelecAmbientesUpdate(estArr: CfgOpcionCombo[]) {
    this.docelecAmbientes = estArr;
  }

  //OJO: esto no se está utilizando!
  docelecCodspctiva: CfgOpcionCombo[] = [];
  docelecCodspctivaUpdate(estArr: CfgOpcionCombo[]) {
    this.docelecCodspctiva = estArr;
  }

  docelecFormascobro: CfgOpcionCombo[] = [];
  docelecFormascobroUpdate(estArr: CfgOpcionCombo[]) {
    this.docelecFormascobro = estArr;
  }

  docelecTiposidcomprador: CfgOpcionCombo[] = [];
  docelecTiposidcompradorUpdate(estArr: CfgOpcionCombo[]) {
    this.docelecTiposidcomprador = estArr;
  }
}
