export class DatosSRI {
    agenteretencion: string;
    agenteretresolucion: string;
    ambienteactual: string;
    ciudad: string;
    contribuyenteespecial: string;
    dirmatriz: string;
    estab: string;
    ivaactual: string;
    maxvtaconsfin: number;
    numfac: number;
    numnc: number;
    obligadocontabilidad: string;
    ptoemi: string;
    razonsocial: string;
    rimpe: string;
    ruc: string;
    telefono: string;
}

export class Detalle {
    aid: string;
    cantart: number;
    cantpermitida: number;
    codigoart: string;
    descripcion: string;
    descuento: number;
    did: string;
    fid: string;
    iva: number;
    nombreart: string;
    obs1: string;
    pctiva: string;
    total: number;
    unidadart: string;
    vuart: number;
}

export class Factura {
    ambiente: string;
    autfechahora: string;
    autnum: string;
    campoadic1: string;
    campoadic2: string;
    campoadic3: string;
    claveacceso: string;
    consid: string;          //cid de la consulta q creó la factura (solo si viene de una consulta)
    descripcion: string;
    detalles: Detalle[];
    direccioncomprador: string;
    direstablecimiento: string;
    estab: string;
    estado: string;
    fecha: string;          //YYYY-MM-DD (GYMD)
    fechaemision: string;   //la fechaEmision en formato DD/MM/YYYY es para el SRI
    fid: string;
    formacobro: string;
    identificacioncomprador: string;
    iva: number;
    mailcomprador: string;
    ncfechafacoriginal: string;
    ncfidoriginal: string;
    ncnumfacoriginal: string;
    nombrearch: string;
    numfac: string;
    pctiva: string;
    pid: string;
    ptoemi: string;
    razonsocialcomprador: string;
    saldo: number;
    secuencial: string;
    telfcomprador: string;
    tipodoc: string;
    tipoidcomprador: string;
    total: number;
    totaldescuento: number;
    totalsinimpuestos: number;
    ultimoLog: string;
    urlpdf: string;
    urlxml: string;
    vconiva: number;
    vsiniva: number;
}

//refleja tabla easymed/...base.../facturas/detart/...did.../
//que contiene datos de los detalles incluyendo datos de la factura donde el art haya sido utilizado
export class DetArt {
    aid: string;
    cantart: number;
    codigoart: string;
    did: string;
    fid: string;
    f_ambiente: string;
    f_descripcion: string;
    f_estado: string;
    f_fecha: string;
    f_numfac: string;
    f_pid: string;
    f_razonsocialcomprador: string;
    f_tipodoc: string;
    nombreart: string;
    pctiva: string;
    total: number;
    unidadart: string;
    vuart: number
}

//refleja tabla easymed/...base.../facturas/cobfac/...cid.../
//que contiene datos de los cobros incluyendo datos de la factura cobrada
//si el cobro es con N/C, se guarda el fid de la NC en el campo fidCruce
//y en la N/C se guarda el fid de la fac en el campo fidCruce
export class CobFac {
    cid: string;
    fid: string;
    cFechaGYMD: string;
    cForma: string;
    cDescrip: string;
    cValor: number;
    fidCruce: string;
    f_ambiente: string;
    f_descripcion: string;
    f_estado: string;
    f_fecha: string;
    f_numfac: string;
    f_pid: string;
    f_razonsocialcomprador: string;
    f_tipodoc: string;
}

//basado en campos del array de detalles en la factura
export class intArrDet {
    fHiddenDetIndex: string;
    fNumdCantArt: number;
    fTxtdCodigoArt: string;
    fTxtdDescripcion: string;
    fTxtdUnidadArt: string;
    fNumdVUArt: number;
    fNumdTotal: number | string;
    fTxtdSujIva: string;
    fTxtdObs1: string;
}

export class LineaXml {
    atribetiq: string;
    contenido: string;
    etiq: string;
    funcion: string;
    indicaciones: string;
    notas: string;
    procesado: string;
    sec: string;
    trad_condic: string;
}

export class LineaPdf {
    contenido: string;
    efisys: string;
    funcion: string;
    notas: string;
    sec: string;
    sitio: string;
    x1: number;
    x2_font: number;
    y1: number;
    y2_long: number;
}

export class DatosEscalasPDF {
    escalapdf: number;
    escalax: number;
    escalay: number;
    saltodet: number;
}

export class FacEstado {
    btnNuevo: string;
    btnCopiar: string;
    btnGuardar: string;
    btnDevolver: string;
    btnBorrar: string;
    btnAnular: string;
    btnProcesar: string;
    btnContinuar: string;
    btnCobrar: string;
    btnImprimir: string;
    btnMail: string;
    nombre: string;
    seq: string;
}

export class CodigosSRI {
    cod: string;
    nombrebase: string;
    nombreAmigableFCobro: string;
}


