import { CfgVarias } from "../interfaces/varias-interfaces";

export const cfgInicial: CfgVarias = {
  logNivel: '3',
  logEstados: 'Solo errores',
  logo: '...',
  logoUrl: '...',
  nombreAmigable: 'Nombre Amigable',
  nombreCorto: 'Nombre Corto',
  sistema: ''
};

//aquí también puede haber
//export let xxx: tipo = ....
//export function jdfkjshd()



