import { NgModule } from '@angular/core'; 
import { Routes, RouterModule } from '@angular/router';
import { NotFoundElemComponent } from '../modulo-comunes/elementos/not-found-elem/not-found-elem.component';
//import { AuthGuardService } from './servicios/auth-guard.service';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'sitio-publico',
        pathMatch: 'full'
    },
    {
        path: 'sitio-publico',
        //loadChildren: '../sitio-publico/sitio-publico.module#SitioPublicoModule'
        loadChildren: () => import('../modulo-sitio-publico/sitio-publico.module').then(m => m.SitioPublicoModule)
    },
    {
        path: 'sitio-usuario',
        //Esto no funcionó:
        //loadChildren: '../sitio-usuario/sitio-usuario.module#SitioUsuarioModule'
        loadChildren: () => import('../modulo-sitio-usuario/sitio-usuario.module').then(m => m.SitioUsuarioModule)
    },
    {
        path: 'sitio-admin',
        loadChildren: () => import('../modulo-sitio-admin/sitio-admin.module').then(m => m.SitioAdminModule)
    },
    {
        path: '**',
        component: NotFoundElemComponent
    }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'}
    )
  ],
  exports: [RouterModule]
})
export class BasicoRuteoModule { }
