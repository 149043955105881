import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { BasicoRuteoModule } from './basico-ruteo.module';
import { NotFoundElemComponent } from '../modulo-comunes/elementos/not-found-elem/not-found-elem.component';
import { RouterModule } from '@angular/router';
import { DataEmpresaService } from '../modulo-comunes/servicios/data-empresa-servicios';
import { LogService } from '../modulo-comunes/servicios/log-servicios';
//import { AuthGuardService } from './servicios/auth-guard.service';

@NgModule({
  imports: [BasicoRuteoModule],
  declarations: [
    NotFoundElemComponent
  ],
  exports: [RouterModule],
  providers: []
})
export class BasicoModule {
  constructor(
    private router: Router,
    private logService : LogService,
    private dataEmpresaService: DataEmpresaService
  ) {
    this.logService.consoleLog('2','MB url: ' + window.location.href);
    //console.log('host: ' + window.location.hostname);
    //console.log('port: ' + window.location.port);
    //console.log('full: ' + window.location.href);
    this.dataEmpresaService.sitioElegidoUpdate(window.location.href);
    this.router.navigate(['/sitio-publico']);
    this.logService.consoleLog('3','Sitio Público');
  }
}